import { BoxProps, styled } from '@mui/material';
import { ComponentProps, SVGProps } from 'react';
import iconSpritePath from 'sprite/sprite.svg?url';
import { IconNames } from 'sprite/types';

interface IconSpriteProps extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: IconNames;
}

/**
 * Simple IconLookup component to return an icon based on the unique name.
 * @param name unique name of the icon you want to render
 * @param SVGProps optional styling by passing SVGProps
 * @returns Icon based on the unique name
 */
const RawIcon = ({ name, fontSize = '1em', ...rest }: IconSpriteProps) => {
  return (
    <svg width={fontSize} height={fontSize} {...rest}>
      <use xlinkHref={`${iconSpritePath}#${name}`} />
    </svg>
  );
};

const MuiStyledIcon = styled(RawIcon, {
  shouldForwardProp: (prop) => prop !== 'sx',
  skipVariantsResolver: true,
})();

interface Props {
  fontSize?: BoxProps['fontSize'] | undefined;
  sx?: BoxProps['sx'];
}

const Icon = ({
  fontSize = 24,
  color,
  sx,
  ...rest
}: Omit<ComponentProps<typeof MuiStyledIcon>, 'fontSize'> & Props) => (
  <MuiStyledIcon
    sx={[
      {
        fontSize,
        minWidth: fontSize,
        color,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  />
);

export default Icon;
