import { Components } from '@mui/material';

// import DarkerGrotesqueBlack from 'assets/fonts/DarkerGrotesque-Black.woff2';
// import HankenGroteskBold from 'assets/fonts/HankenGrotesk-Bold.woff2';
// import HankenGroteskMedium from 'assets/fonts/HankenGrotesk-Medium.woff2';
// import HankenGroteskRegular from 'assets/fonts/HankenGrotesk-Regular.woff2';
import OpenSansBold from 'assets/fonts/OpenSans-Bold.woff2';
import OpenSansExtraBold from 'assets/fonts/OpenSans-ExtraBold.woff2';
import OpenSansMedium from 'assets/fonts/OpenSans-Medium.woff2';
import OpenSansRegular from 'assets/fonts/OpenSans-Regular.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: /*css */ `
    html {
      font-size: 20px;
      height: 100svh;
      overflow-y: auto;
      overflow-x: hidden;
      @media only screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    body {
      overflow-x: hidden;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      @media print {
        margin: 20mm;
      }
    }
    @font-face {
      font-family: 'Darker Grotesque';
      font-style: normal;
      font-weight: 900;
      font-display: swap;
      src:
        local('Darker Grotesque'),
        url(${OpenSansExtraBold}) format('woff2');
    }
    @font-face {
      font-family: 'Hanken Grotesk';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src:
        local('Hanken Grotesk'),
        url(${OpenSansRegular}) format('woff2');
    }
    @font-face {
      font-family: 'Hanken Grotesk';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src:
        local('Hanken Grotesk'),
        url(${OpenSansMedium}) format('woff2');
    }
    @font-face {
      font-family: 'Hanken Grotesk';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src:
        local('Hanken Grotesk'),
        url(${OpenSansBold}) format('woff2');
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }
    @media print {
      .no-print {
        display: none !important;
      }
      .print-only {
        display: block !important;
      }
      @page {
        size: A4;
        margin: 0 !important;
      }
      #page-container,
      .scrollable-page,
      .ps,
      .panel {
        height: 100% !important;
        width: 100% !important;
        display: inline-block;
      }
      html,
      body {
        /* setting height:initial resolved issue where an empty page is added in print */
        height: initial !important;
        margin: 0 !important;
        padding: 2rem 1rem!important;
      }
    }
    .print-only {
      display: none;
    }
    /* Disable text selection on mobiles.
    * coarse -  pointing device of limited accuracy, such as a finger on a touchscreen.
    */
    @media (pointer: coarse) {
      *:not(input, textarea) {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
      }
    }
  `,
};
