import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import config from 'config/config';

import { Routes } from 'constants/routes.constants';

interface Props {
  withRedirect?: boolean;
  variant?: 'light' | 'dark';
}

const TenantLogo = ({ withRedirect = true, variant = 'light' }: Props) => {
  const logo =
    variant === 'light' ? (config.ASSETS.logo?.light ?? '') : (config.ASSETS.logo?.dark ?? '');

  if (!logo) return null;
  return (
    <Box component={withRedirect ? Link : Box} to={Routes.News} display="flex">
      <img
        src={logo}
        alt="logo"
        style={{ objectFit: 'contain', maxWidth: '100%', height: '48px' }}
      />
    </Box>
  );
};

export default TenantLogo;
