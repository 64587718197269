import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Browser } from '@capacitor/browser';
import { StatusBar } from '@capacitor/status-bar';
import { flush } from '@sentry/core';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { isAndroid, isNative } from 'utils/capacitor.utils';
import { captureException } from 'utils/sentry.utils';

import { useLogOut } from 'queries';
import { Duration } from 'queries/constants';
import { useSelectedLocale } from 'services/i18n';
import { parseJwt } from 'services/prefetcher/util';

import { Splash } from 'components/@splash';

import IdentityRetry from './IdentityRetry';
import { getB2cLocale } from './utils';

const MSAL_KEY = 'msal-access-token';
const MSAL_STATUS_KEY = 'msal.interaction.status';

export let getAccessToken: () => Promise<string | null> = async () => null;

interface Props {
  children: ReactNode;
  scopes: Array<string>;
}

const IdentityConsumer = ({ children, scopes }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance: msalInstance, inProgress } = useMsal();
  const isAuthenticationInProgress = useRef(false);
  const ssoProvider = useRef<string | undefined>(undefined);
  const [hasToken, setHasToken] = useState(false);
  const { locale } = useSelectedLocale();
  const { logOut } = useLogOut();

  const acquireTokenRedirect = useCallback(async () => {
    if (isNative) {
      await msalInstance.acquireTokenRedirect({
        scopes,
        prompt: 'login',
        extraQueryParameters: { ui_locales: getB2cLocale(locale) },
        onRedirectNavigate: (url) => {
          Browser.open({ url });
          return false;
        },
      });
    } else {
      //console.info('acquireTokenRedirect - browser ', ssoProvider.current);
      await msalInstance.acquireTokenRedirect({
        scopes,
        domainHint: ssoProvider.current,
        extraQueryParameters: { ui_locales: getB2cLocale(locale) },
      });
    }
  }, [locale, msalInstance, scopes]);

  const handleAuthentication = useCallback(async () => {
    try {
      await msalInstance.handleRedirectPromise();
    } catch (error) {
      captureException(error);
      //Flush does not exist yet in @sentry/capacitor
      if (!isNative) await flush(2000);

      await logOut();
      throw error;
    }

    if (
      (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Login) &&
      !isAuthenticationInProgress.current
    ) {
      if (!isAuthenticated) {
        // Don't redirect when interaction is in progress
        if (!!accounts.length || isAuthenticationInProgress.current) return;

        isAuthenticationInProgress.current = true;

        await acquireTokenRedirect();
      }

      if (isAuthenticated && !!accounts.length) {
        getAccessToken = async () => {
          // Get & return the token, if it doesn't work, redirect to login page
          try {
            let extraParams = {};
            let parsedToken = null;

            // Get the token from the cache
            // The reason we have our own cache is because the MSAL cache does not work with multiple scopes https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1240
            // If they ever fix this in the future, we can remove our own implementation
            const cachedAccessToken = localStorage.getItem(MSAL_KEY);
            if (!!cachedAccessToken) {
              parsedToken = parseJwt(cachedAccessToken);
              const expiryInMs = parsedToken.exp * 1000 - Date.now();
              // If the token is still valid, return it, we use a safety margin of 5 minutes
              if (expiryInMs - Duration.FIVE_MIN > 0) return cachedAccessToken;
            }

            if (parsedToken && parsedToken.idp) {
              ssoProvider.current = parsedToken.idp;
              extraParams = { domain_hint: parsedToken.idp };
            }

            //Token is not in cache or expired, get it from the server
            const { accessToken } = await msalInstance.acquireTokenSilent({
              scopes,
              prompt: 'login',
              account: accounts[0],
              forceRefresh: true,
              extraQueryParameters: extraParams,
            });

            if (!accessToken) await logOut();

            // Store the new fresh access token in the cache for later use
            localStorage.setItem(MSAL_KEY, accessToken);

            return accessToken;
          } catch (error) {
            if (
              error instanceof InteractionRequiredAuthError ||
              error instanceof BrowserAuthError
            ) {
              await acquireTokenRedirect();
              return null;
            }

            throw error;
          }
        };

        const token = await getAccessToken();

        setHasToken(!!token);
        msalInstance.setActiveAccount(accounts[0]);
      }

      isAuthenticationInProgress.current = false;
    }
  }, [inProgress, msalInstance, logOut, isAuthenticated, accounts, acquireTokenRedirect, scopes]);

  const clearCache = useCallback(async () => {
    sessionStorage.removeItem(MSAL_STATUS_KEY);
    await msalInstance.logoutRedirect({ onRedirectNavigate: () => false });
  }, [msalInstance]);

  useEffect(() => {
    if (!isNative) handleAuthentication();
  }, [handleAuthentication]);

  useEffect(() => {
    if (isAuthenticated && isNative) {
      handleAuthentication();
    }
  }, [handleAuthentication, isAuthenticated]);

  useEffect(() => {
    if (isAndroid) {
      const setStatusBarBackground = async () => {
        await StatusBar.setOverlaysWebView({ overlay: true }); // Hide native status bar
      };
      setStatusBarBackground();
    }
  }, []);

  if (!hasToken)
    return (
      <>
        {isNative &&
        (inProgress === InteractionStatus.Login ||
          inProgress === InteractionStatus.Logout ||
          inProgress === InteractionStatus.None) &&
        !isAuthenticated ? (
          <IdentityRetry
            onHandleRetry={() => {
              if (sessionStorage.getItem(MSAL_STATUS_KEY)) {
                clearCache();
              }

              handleAuthentication();
            }}
          />
        ) : (
          <Splash isLoading />
        )}
      </>
    );

  return <>{children}</>;
};

export default IdentityConsumer;
