import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { User, UserUpdateParams } from 'types/user.types';

interface Params {
  userId: User['spencerUuid'];
  data: UserUpdateParams;
}

const putUser = async ({ userId, data }: Params) => apiService.putUser(userId, data);

export const useUserUpdate = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: putUser,
    onMutate: async ({ data, userId }) => {
      const queryKey = QueryKeys.users.byId(userId);
      await queryClient.cancelQueries({ queryKey });

      const mappedData = { ...data, phonePrivateMobile: data.mobilePrivate };

      const previousUser = queryClient.getQueryData<User>(queryKey);
      queryClient.setQueryData<User>(queryKey, (old) => ({ ...old, ...mappedData }) as User, {
        updatedAt: Date.now(),
      });

      return { previousUser, queryKey };
    },
    onError: (_err, _variables, context) => {
      if (context?.previousUser && context.queryKey) {
        queryClient.setQueryData<User>(context.queryKey, context.previousUser);
      }
    },
  });

  return { updateUser: mutate, isLoading: isPending };
};
